import React, { useState } from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx"
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { get } from 'helpers/api_helper';
import moment from 'moment';

export const ExportJsonToExcelAPI = ({ 
    apiPath = ``, 
    fileName = 'excel', 
    dateColumns = [],
    mapHeaderColumn = {},
    colorBox = 'btn-success'
}) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const timeStamp = `-${moment().unix()}-`
    const fileExtension = ".xlsx";

    const exportToCSV = (jsonData = [], fileName = 'excel', dateCol = [], mapColumn = {}) => {

        if (jsonData.length === 0) {
            jsonData.push({})
        } 
        let jsonDataTZ = jsonData.map(row => {
            console.log("🚀 ~ exportToCSV ~ dateCol:", dateCol)
            const DATE_COL_DEF = ['createdAt', 'createdDate', 'updatedAt', 'updatedDate'] 
            const DATE_COL = [...new Set([ ...DATE_COL_DEF, ...dateCol])]
            console.log("🚀 ~ jsonDataTZ ~ DATE_COL:", DATE_COL)
            for (const keyDC of DATE_COL) {
                if (row[keyDC]) {
                    row[keyDC] = moment(row[keyDC]).format("YYYY-MM-DD HH:mm:ss")
                }
            }
            return row
        });

        let jsonDataColumn = jsonDataTZ.map(item => {
            let row = {}
            for (let key of Object.keys(mapColumn)) {
                row[mapColumn[key]] = item[key] || ''
            }
            
            return row
        });


        const ws = XLSX.utils.json_to_sheet(jsonDataColumn);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + timeStamp + fileExtension);
        setExportLoading(false)
    };

    const [ exportLoading, setExportLoading ] = useState()
    const [ rowDatas, setRowDatas] = useState([]);

    const API = {
        fetchExcelData: async () => {
            const result = await get(process.env.REACT_APP_BACKEND_URL + apiPath)
            const resData = result.data
            const resOther = result.other
            console.log("🚀 ~ fetchContent: ~ resData:", resData)

            setRowDatas(resData)

            let tmpMapHeaderColumn = _.cloneDeep(mapHeaderColumn)// สร้างมาชั่วคราวเพื่ออัพเดตค่า
            if (resOther?.removeField && resOther?.removeField?.length) {
                const removeField = resOther?.removeField ?? []
                for(let rf of removeField) {
                    console.log("🚀 ~ fetchExcelData: ~ rf:", rf)
                    delete tmpMapHeaderColumn[rf]
                }
            }
            exportToCSV(resData, fileName, dateColumns, tmpMapHeaderColumn)
        }
    }


    const handleExport = () => {
        setExportLoading(true)
        API.fetchExcelData()
    } 


    return (
        <a 
            className={`btn ${colorBox}`} 
            onClick={handleExport}
        >
            {
                exportLoading
                ? <Spinner size="sm" className="" color="light" setLoading={setExportLoading}  style={{ zoom: '0.8'}}/>
                : <i className='bx bx-export'/>
            }
        </a>
    );
};