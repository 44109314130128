import React, { useEffect, useState } from "react"

import {
    Col,
    Row,
    Card,
    CardBody,
    // CardTitle,
    // CardSubtitle,
    // CardImg,
    // CardText,
    // CardHeader,
    // CardImgOverlay,
    // CardFooter,
    // CardDeck,
    Container,
    Label,
    Input,
    InputGroup,
    Form,
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane,
    Button,
    Spinner,
    Tooltip
} from "reactstrap";

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from '@tinymce/tinymce-react';

import classnames from "classnames";

import { checkScope } from "helpers/utility_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";

import { get, post } from "helpers/api_helper";

//import components
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinners from "components/Common/Spinner";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';  
import logo from "../../assets/images/Logo_Design_NEW.png"; // only logo
import fn_helper from "helpers/fn_helper";

const QuestionairePdpa = props => {
    const navigate = useNavigate()

    const { questionnaireUUID } = useParams();

    const [ contentDetailLoading, setContentDetailLoading ] = useState(true);
    const [ isReadPdpa, setIsReadPdpa ] = useState(false);
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [countryCode, setCountryCode] = useState('66');
    const [ isAccept, setIsAccept ] = useState(false);
    const [ apiData, setApiData] = useState({})

    const [ aiPrompt, setAiPrompt ] = useState('')

    localStorage.removeItem('aiPrompt')

    const API = {
        fetchContent: async () => {
            setContentDetailLoading(true)
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/public/view-pdpa/' + questionnaireUUID)
            const resData = result.data
            console.log("🚀 ~ fetchContent: ~ resData:", resData)

            // PREFILE INPUT FORM
            setApiData(resData)
            
            // setContentDetailLoading(false)
        }
    }

    useEffect(() => {
        API.fetchContent()
    }, [])

    if (contentDetailLoading) {
        return <Spinners setLoading={setContentDetailLoading} />
    }

    document.title = `Questionnaire` + process.env.REACT_APP_CMS_TITLE

    return (
        <React.Fragment>
            <div className="page-contentx">
                <Container 
                    fluid={false} 
                    
                >
                    {/* Render Breadcrumb */}
                    {/* <Breadcrumbs 
                        title={"Questionnaire"} 
                        breadcrumbItems={[
                            {title: "Questionnaire", link: "questionnaire"}, 
                            {title: "Create Category", link: "category"},
                        ]} /> */}
                    <Row>
                        <Col>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                            >
                                <Card className="bCardPdpa">
                                    <CardBody>
                                        <div className="bHeader">
                                            <img src={logo} alt="" />
                                        </div>
                                        <div className="bContent">
                                            
                                            {
                                                !isReadPdpa
                                                ? (
                                                    <div>
                                                        <Row className="mb-3">
                                                            <div className="bContentBanner">
                                                                <img style={{ width: '100%'}} src={apiData.image_banner}/>
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <div className="flex">
                                                                <div className="bTitle">
                                                                    <h3 className="text-center">{apiData.title}</h3>
                                                                </div>
                                                                <div className="bAcceptPdpa">
                                                                    <div className="bTopic">
                                                                        <h6>Phone Number: </h6>
                                                                    </div>
                                                                    <div className="bTopic" style={{ display:'flex', flexDirection:'column'}}>
                                                                        <PhoneInput
                                                                            className="custom-form"
                                                                            country={'th'}  // ตั้งค่าเริ่มต้นเป็นประเทศไทย
                                                                            autoFormat={false}
                                                                            onChange={(value, country) => {
                                                                                setPhoneNumber(value);
                                                                                setCountryCode(country.dialCode); // ตั้งค่ารหัสประเทศเมื่อเปลี่ยน
                                                                            }}
                                                                            placeholder={`+${countryCode} Please provide the correct phone number to receive the prize.`} 
                                                                        />
                                                                        <label className="mt-1 mb-0 text-secondary form-label">Please provide the correct phone number to receive the prize.</label>
                                                                        {/* <Input
                                                                            className="form-control mw-100 custom-form"
                                                                            type="text"
                                                                            name="question_text"
                                                                            placeholder="Please provide the correct phone number to receive the prize."
                                                                            value={phoneNumber}
                                                                            maxLength={12}
                                                                            onInput={fn_helper.FNFORM.handleNumberOnly}
                                                                            onChange={(el) => {
                                                                                setPhoneNumber(el.target.value)
                                                                            }}
                                                                        /> */}
                                                                    </div>
                                                                    
                                                                </div>
                                                                <div className="bAcceptPdpa2">
                                                                    {
                                                                        !isReadPdpa
                                                                        ? (
                                                                            <div className="form-check form-check-end mb-3">
                                                                                <input
                                                                                    id="accept-pdpa"
                                                                                    className="form-check-input "
                                                                                    type="checkbox"
                                                                                    checked={isAccept}
                                                                                    onClick={() => {
                                                                                        setIsAccept(!isAccept)
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label color-white"
                                                                                    htmlFor="accept-pdpa"
                                                                                    // onClick={()=> setIsReadPdpa(true)}
                                                                                >
                                                                                    I confirm that I have read and accept the Activity Rules, Agreement, and Privacy Policy.
                                                                                </label>
                                                                            </div>
                                                                        )
                                                                        : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            
                                                                {/* <div dangerouslySetInnerHTML={{ __html: apiData.box1 }}/> */}
                                                        </Row>
                                                    </div>
                                                )
                                                : (
                                                    <div>
                                                        <Row className="mb-3">
                                                            <div className="bTopic">
                                                                <h6>Activity Rules:</h6>
                                                                {/* <span>Message to warning people who have given unqualified answers multiple times.</span> */}
                                                            </div>
                                                            <div className="bContentPdpa">
                                                                <div dangerouslySetInnerHTML={{ __html: apiData.box1 }}/>
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <div className="bTopic">
                                                                <h6>Agreement:</h6>
                                                                {/* <span>Message to people who have failed the scoring tests.</span> */}
                                                            </div>
                                                            <div className="bContentPdpa">
                                                                <div dangerouslySetInnerHTML={{ __html: apiData.box2 }}/>
                                                            </div>
                                                        </Row>
                                                        <Row className="">
                                                            <div className="bTopic">
                                                                <h6>Privacy Policy:</h6>
                                                                {/* <span>Message to people who completed the survey</span> */}
                                                            </div>
                                                            <div className="bContentPdpa">
                                                                <div dangerouslySetInnerHTML={{ __html: apiData.box3 }}/>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                            

                                        </div>
                                        <div className="bFooter">
                                            <Row className="mb-3">
                                                <Col>
                                                    <div style={{display: "flex", justifyContent: "center", gap: 12 }}>
                                                        {/* <Link to={"/questionaire/create"} color="primary" className="btn btn-primary waves-effect waves-light">Draft Questionaire</Link> */}
                                                        {
                                                            !isReadPdpa
                                                            ? (
                                                                <div className="d-flex flex-column justify-center gap-2 text-center">
                                                                    <label
                                                                        className="form-check-label color-white"
                                                                        // htmlFor="accept-pdpa"
                                                                        style={{ textDecoration: 'underline' }}
                                                                        onClick={()=> setIsReadPdpa(true)}
                                                                    >
                                                                        View Agreement
                                                                    </label>
                                                                    <Button 
                                                                        type="button" 
                                                                        color="success" 
                                                                        className="btn btn-primary waves-effect waves-light" style={{ minWidth: 120 }}
                                                                        onClick={()=> {
                                                                            // const redirectLink = `${process.env.REACT_APP_FRONTWEB_URL}?uuid=${questionnaireUUID}`
                                                                            // window.location.href = redirectLink
                                                                            localStorage.setItem('phoneNumber', phoneNumber)
                                                                            localStorage.setItem('aiPrompt', aiPrompt)
                                                                            navigate('/webchat/' + questionnaireUUID)
                                                                        }} 
                                                                        disabled={!isAccept || !phoneNumber || phoneNumber.length < 10}
                                                                        >
                                                                        Join the Event
                                                                    </Button>
                                                                </div>
                                                                
                                                            )
                                                            : (
                                                                <Button 
                                                                    type="button" 
                                                                    color="success" 
                                                                    className="btn btn-primary waves-effect waves-light" style={{ minWidth: 120 }}
                                                                    onClick={()=> {
                                                                        setIsReadPdpa(false)
                                                                    }} 
                                                                >
                                                                    Back
                                                                </Button>
                                                            )
                                                        }
                                                        {/* <Button type="button" color="danger" className="btn btn-primary waves-effect waves-light"  style={{ minWidth: 120 }}>
                                                            Disagree
                                                        </Button> */}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div>
                <Input
                    type="textarea"
                    rows="10"
                    onChange={(el) => {
                        setAiPrompt(el.target.value)
                    }}
                />
            </div> */}
        </React.Fragment>
    )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };


export default withTranslation()(QuestionairePdpa)
