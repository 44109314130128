import React from "react"
import { Container, Row, Col } from "reactstrap"

const MyTooltip = ({
  label = '',
  children 
}) => {
  return (
    <React.Fragment>
      <span className="my-tooltip-main">
          {children}
        <span className="my-tooltip-child fadex">{label}</span>
      </span>
    </React.Fragment>
  )
}

export default MyTooltip
