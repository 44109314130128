import React, { useEffect, useState } from "react"

import {
    Col,
    Row,
    Card,
    CardBody,
    // CardTitle,
    // CardSubtitle,
    // CardImg,
    // CardText,
    // CardHeader,
    // CardImgOverlay,
    // CardFooter,
    // CardDeck,
    Container,
    Label,
    Input,
    InputGroup,
    Form,
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane,
    Button,
    Spinner,
    Tooltip
} from "reactstrap";

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from '@tinymce/tinymce-react';

import classnames from "classnames";

import { checkScope } from "helpers/utility_helper";

// Formik Validation
import * as Yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";

// action
import { apiError } from "../../store/actions";
import { createQuestionaireCategory, createQuestionaireCategoryReset } from "../../store/actions"
import { get, post } from "helpers/api_helper";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb2';
import { withTranslation } from "react-i18next";
import '../../components/Common/ts-css.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "components/Common/Spinner";
import useQuestionnaireStore from "../../zustand/store/QuestionnaireModule";
import fn_helper from "helpers/fn_helper";

const DEF_OPTION_GENDER = [
    {
        label: 'Any',
        value: 'any'
    },
    {
        label: 'Male',
        value: 'male'
    },
    {
        label: 'Female',
        value: 'female'
    },
    
]

const DEF_OPTION_NATIONALITY = [
    {
        label: 'Any',
        value: 'any'
    },
    {
        label: 'Thai',
        value: 'Thai'
    }
]

const DEF_OPTION_MATIAL_STATUS = [
    {
        label: 'Any',
        value: 'any'
    },
    {
        label: 'Single',
        value: 'Single'
    },
    {
        label: 'Married',
        value: 'Married'
    },
    
]

const LANG_CODE_OPTION = [
    { id:'en-US', name:'EN' },
    { id:'th-TH', name:'TH' },
    { id:'ja-JP', name:'JP' },
    { id:'vi-VN', name:'VT' },
    { id:'zh-TW', name:'CN' },
    { id:'zh-CN', name:'CN SIMPLIFIED' },
]

const QuestionaireCategoryTemplate = props => {
    const { pageView = 'create' } = props

    // CENTER STATE
    const questionnaireStore = useQuestionnaireStore(); // STATE
    const { setStep1, resetQuestionnaire } = useQuestionnaireStore(); // ACTION

    const isBackPage = questionnaireStore?.isLoaded ?? false;

    const navigate = useNavigate()
    const [ rootMenu, subMenu, action] = location.pathname.split('/')

    const { categoryId } = useParams();

    const [ sendQuestion, setSendQuestion ] = useState(false)

    const [verticalActiveTab, setverticalActiveTab] = useState("1");

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpen2, setTooltipOpen2] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggle2 = () => setTooltipOpen2(!tooltipOpen2);

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    const [genderInvalid, setGenderInvalid] = useState(true)
    const genderInvalidClass = classnames({
        'select-basic-single': true,
        'is-invalid': genderInvalid
    })

    const [nationalityInvalid, setNationalityInvalid] = useState(true)
    const nationalityInvalidClass = classnames({
        'select-basic-single': true,
        'is-invalid': nationalityInvalid
    })

    const [martialInvalid, setMartialInvalid] = useState(true)
    const martialInvalidClass = classnames({
        'select-basic-single': true,
        'is-invalid': martialInvalid
    })

    const [genderDropdown, setGenderDropdown] = useState(DEF_OPTION_GENDER);
    const [genderSelected, setGenderSelected] = useState();

    const [nationalityDropdown, setNationalityDropdown] = useState(DEF_OPTION_NATIONALITY);
    const [nationalitySelected, setNationalitySelected] = useState();

    const [martialStatusDropdown, setMartialStatusDropdown] = useState(DEF_OPTION_MATIAL_STATUS);
    const [martialStatusSelected, setMartialStatusSelected] = useState();

    const [contentDetailLoading, setContentDetailLoading] = useState(!isBackPage);


    // ========== Form Validation ========== //
    const validationSchema = Yup.object({
        lang_code: Yup.string().max(255).required("Select Language Code"),
        category_name: Yup.string().max(60).required("Please Enter Category Name"),
        survey_name: Yup.string().max(60).required("Please Enter Survey Name"),
        purpose: Yup.string().required("Please Enter Purpose"),
        // age_range_start: Yup.number()
        //     .integer()
        //     .positive()
        //     .required("Please Enter Age range start")
        //     .min(1)
        //     .max(99),
        // age_range_end: Yup.number()
        //     .integer()
        //     .positive()
        //     .moreThan(Yup.ref("age_range_start"), "Please enter Age range end more than Age range start")
        //     .required("Please Enter  Age range end")
        //     .min(1)
        //     .max(99),
        // gender: Yup.string()
        //     .required("Please Enter Gender"),
        // nationality: Yup.string()
        //     .required("Please Enter Nationality"),
        // martial_status: Yup.string()
        //     .required("Please Enter Martial Status"),
        
        criterias: Yup.array().of(
            // Yup.object().shape({
            //     criteria_name: Yup.string().max(255).required("Fill Text"),
            //     criteria_checkbox: Yup.bool(),
            //     criteria_text: Yup.string().max(255).required("Fill Text"),
            //     criteria_context: Yup.string().max(255).required("Fill Text"),
            //     criteria_good: Yup.string().max(255).required("Fill Text"),
            //     criteria_poor: Yup.string().max(255).required("Fill Text"),
            //     criteria_bad: Yup.string().max(255).required("Fill Text"),
            // })
            Yup.object().shape({
                criteria_name: Yup.string().max(255).required("Fill Text"),
                criteria_checkbox: Yup.bool(),
                criteria_text: Yup.string().max(255)
                    .when('criteria_checkbox', {
                        is: true,
                        then: (schema) => schema.required("Fill Text"),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                criteria_context: Yup.string().max(255)
                    .when('criteria_checkbox', {
                        is: true,
                        then: (schema) => schema.required("Fill Text"),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                criteria_good: Yup.string().max(255)
                    .when('criteria_checkbox', {
                        is: true,
                        then: (schema) => schema.required("Fill Text"),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                criteria_poor: Yup.string().max(255)
                    .when('criteria_checkbox', {
                        is: true,
                        then: (schema) => schema.required("Fill Text"),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                criteria_bad: Yup.string().max(255)
                    .when('criteria_checkbox', {
                        is: true,
                        then: (schema) => schema.required("Fill Text"),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                })
            ),
            // remarks: Yup.string().required("Please Enter Remarks"),
            rules: Yup.string().required("Please Enter Rules"),

            reminder_message: Yup.string().required("Please Enter Reminder Message"),
            fail_message: Yup.string().required("Please Enter Fail Message"),
            pass_message: Yup.string().required("Please Enter Pass Message"),
    });

    const [ updatedValidationSchema, setUpdatedValidationSchema ] = useState(validationSchema);
    
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            id: pageView == 'create' ? undefined : '',
            lang_code: '',
            category_name: '',
            survey_name: '',
            purpose: '',
            // age_range_start: '',
            // age_range_end: '',
            // gender: '',
            // nationality: '',
            // martial_status: '',

            criterias: [
                {
                    criteria_name: "Relevance",
                    criteria_checkbox: true,
                    criteria_text: "whether the generated question is related to the respondent's answer and the interview topic.",
                    criteria_context: "I really enjoyed experiencing the traditional festivals in Japan.",
                    criteria_good: "Which traditional festivals in Japan did you attend, and what were the highlights for you during these festivals?",
                    criteria_poor: "What kind of Japanese food did you try?",
                    criteria_bad: "What is your favorite movie genre?",
                },
                {
                    criteria_name: "Clarity",
                    criteria_checkbox: true,
                    criteria_text: "Whether the generated question is clearly expressed and easy to understand.",
                    criteria_context: "I loved visiting the historic sites in Kyoto.",
                    criteria_good: "What specific historic sites in Kyoto did you visit, and what did you find most interesting about them?",
                    criteria_poor: "Which historic sites in Kyoto did you visit?",
                    criteria_bad: "What about Kyoto?",
                },
                {
                    criteria_name: "Engagement Potential",
                    criteria_checkbox: true,
                    criteria_text: "Whether the generated question encourages the respondent to provide detailed, enthusiastic, or engaging responses.",
                    criteria_context: "I was amazed by the architecture of the temples in Kyoto.",
                    criteria_good: "Which temples in Kyoto did you find the most impressive, and what specific architectural features or historical aspects captured your interest?",
                    criteria_poor: "What did you like about the temples in Kyoto?",
                    criteria_bad: "Did you see any buildings?",
                },
                {
                    criteria_name: "Response Usefulness",
                    criteria_checkbox: true,
                    criteria_text: "The practical usefulness of the respondent's answers generated by the question for further analysis or understanding the interview subject.",
                    criteria_context: "I found the public transportation system in Japan very efficient.",
                    criteria_good: "Can you describe any specific aspects of the public transportation system in Japan that you found particularly efficient or challenging, and how did it compare to the system in your home country?",
                    criteria_poor: "Did you find it easy to use?",
                    criteria_bad: "Did you take the bus?",
                },
                {
                    criteria_name: "Information Gain",
                    criteria_checkbox: true,
                    criteria_text: "Whether the generated question leads the respondent to provide new, in-depth information.",
                    criteria_context: "I enjoyed exploring the various districts of Tokyo, especially Shibuya and Shinjuku.",
                    criteria_good: "What specific activities or experiences did you enjoy most in Shibuya and Shinjuku, and how did they differ?",
                    criteria_poor: "Did you find Shibuya or Shinjuku more interesting?",
                    criteria_bad: "Did you visit Tokyo?",
                },
            ],
            // remarks: "* For the Engagement Potential index, it's important not to ask overly specialized questions that the respondent may not be able to answer.\n* In the Information Gain index, it's important to ensure that the questions do not repeat previously asked questions or any predetermined fixed questions.\n* AI needs to follow the predetermined rules.",
            rules: "1. Reminder Message: If the user scores low more than 2 different questions out of the 8 total, show the reminder message.\n2. Fail Message: If the user scores low on 6 different questions out of the 8 total, display the fail message and end the interview.",
            reminder_message: "Warning: Your responses have not met the standard. To continue and earn the reward, provide thorough and relevant answers. Further issues will end the survey and disqualify you. Thank you for your cooperation!​ If you're ready to answer the next question, please type 'Next'.​",
            fail_message: "Fail: ​We regret to inform you that due to multiple failures in passing the scoring test, you will not be able to continue with this survey. As a result, you will also be ineligible to receive the corresponding reward.​ We appreciate your time and participation, and we hope to invite you to participate in future studies. Thank you for your understanding!",
            pass_message: "Pass: We are pleased to inform you that you have completed the survey! Your contribution is highly valuable to us.　We will use the phone number you provided to send the reward to your account within 30 days after the end of the activity. Once again, we sincerely thank you for taking the time to participate in the survey.",

        },
        validationSchema: updatedValidationSchema,
        onSubmit: (values) => {
            localStorage.setItem('categoryData', JSON.stringify(values))
            setStep1({ step1: values }) // เก็บค่าเข้า STATE CENTER
            if (['update', 'view'].includes(pageView)) {
                localStorage.setItem('categoryId', JSON.stringify(values.id))
                navigate(`/questionnaire/edit/` + categoryId)
            } else {
                console.log("🚀 ~ QuestionaireCategory ~ values:", values)
                navigate(`/questionnaire/create`)
            }
        }
    })

    // Custom handleChange function
    const customHandleChange = (e, index) => {
        const { name, value } = e.target;

        // Example: Perform custom logic, e.g., transforming value
        let transformedValue = value; // Example transformation

        // Update Formik's field value with transformed value
        validation.setFieldValue(name, transformedValue);

        // Call Formik's handleChange
        validation.handleChange(e);
    };
    
    const preFillForm = (formData = {}) => {

        validation.setFieldValue('id', formData.id)
        validation.setFieldValue('lang_code', formData.lang_code)
        validation.setFieldValue('category_name', formData.category_name)
        validation.setFieldValue('survey_name', formData.survey_name)
        validation.setFieldValue('purpose', formData.purpose)

        validation.setFieldValue('rules', formData.rules)
        validation.setFieldValue('remarks', formData.remarks)

        validation.setFieldValue('reminder_message', formData.reminder_message)
        validation.setFieldValue('fail_message', formData.fail_message)
        validation.setFieldValue('pass_message', formData.pass_message)


        // SET VIEW ARRAY FORM
        console.log("🚀 ~ fetchContent: ~ formData.criterias:", formData.criterias)
        let preFillCriterias = []
        if (formData.criterias && Array.isArray(formData.criterias)) {
            preFillCriterias = formData.criterias
        }
        validation.setFieldValue('criterias', preFillCriterias)

    }
    

    const API = {
        fetchContent: async () => {
            setContentDetailLoading(true)
            const result = await get(process.env.REACT_APP_BACKEND_URL + '/v1/admin/questionnaire/category/' + categoryId)
            const resData = result.data
            console.log("🚀 ~ fetchContent: ~ resData:", resData)

            // PREFILE INPUT FORM
            preFillForm(resData)
            
            setContentDetailLoading(false)
        }
    }

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ questionnaireStore:", questionnaireStore)

        if (isBackPage) {
            const oldData = questionnaireStore.step1
            preFillForm(oldData)
        }

        if (pageView !== 'create') {
            API.fetchContent()
        }

    }, [])

    if (contentDetailLoading && pageView !== "create") {
        return <Spinners setLoading={setContentDetailLoading} />
    }

    const PageAction = pageView
    document.title = `Questionnaire | ${PageAction} Category | ` + process.env.REACT_APP_CMS_TITLE

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={false}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs 
                        title={"Questionnaire"} 
                        breadcrumbItems={[
                            {title: "Questionnaire", link: "questionnaire"}, 
                            {title: "Create Category", link: "category"},
                        ]} />
                    <Row>
                        <Col>
                        <FormikProvider value={validation}>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3">
                                            <h5 className="mb-3">{_.startCase(pageView)} Questionnaire Category</h5>
                                            <Col className="col-12">
                                                <label
                                                    htmlFor="category_name"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Language
                                                </label>
                                                <div className="col-md-4">
                                                    <Select
                                                        id="lang_code"
                                                        placeholder="Select Language"
                                                        value={fn_helper.FNFORM.getObjectValue(LANG_CODE_OPTION, `${validation.values.lang_code}`)}
                                                        onChange={(value) => validation.setFieldValue("lang_code", value.id)}
                                                        getOptionValue={(option) => option.id}
                                                        getOptionLabel={(option) => option.name}
                                                        options={LANG_CODE_OPTION}
                                                        className={`select2-selection ${validation.touched.lang_code && validation.errors.lang_code ? "input-err" : ""}` }
                                                        isDisabled={pageView === "view"}
                                                    />
                                                    {/* {
                                                        // (validation.touched.lang_code && validation.errors.lang_code) && (
                                                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.lang_code}</div>
                                                        // )
                                                    } */}
                                                    {validation.touched.lang_code && validation.errors.lang_code ? (
                                                        <div type="invalid" className="invalid-feedback d-block">{validation.errors.lang_code}</div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="col-12">
                                                <label
                                                    htmlFor="category_name"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Category
                                                </label>
                                                <div className="col-md-4">
                                                    <Input
                                                        id="category_name"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="eg. Travel in Japan / Skincare / Car"
                                                        name="category_name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.category_name}
                                                        invalid={
                                                            validation.touched.category_name && validation.errors.category_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.category_name && validation.errors.category_name ? (
                                                        <FormFeedback type="invalid">{validation.errors.category_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="col-12">
                                                <label
                                                    htmlFor="survey_name"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Name
                                                </label>
                                                <div className="col-md-4">
                                                    <Input
                                                        id="survey_name"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="eg. survey_name"
                                                        name="survey_name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.survey_name}
                                                        invalid={
                                                            validation.touched.survey_name && validation.errors.survey_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.survey_name && validation.errors.survey_name ? (
                                                        <FormFeedback type="invalid">{validation.errors.survey_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-12 mb-3">
                                                <label
                                                    htmlFor="purpose"
                                                    className="col-form-label"
                                                >
                                                    Survey Purpose
                                                </label>
                                                <div>
                                                    <Input
                                                        id="purpose"
                                                        className="form-control"
                                                        type="textarea"
                                                        rows="5"
                                                        placeholder="eg. The purpose of this interview is to understand the travel experiences of foreign tourists in Japan, to gain a deep understanding of their travel motivations, cultural adaptation and overall satisfaction in Japan"
                                                        name="purpose"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.purpose}
                                                        invalid={
                                                            validation.touched.purpose && validation.errors.purpose ? true : false
                                                        }
                                                    />
                                                    {validation.touched.purpose && validation.errors.purpose ? (
                                                        <FormFeedback type="invalid">{validation.errors.purpose}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* <Row className="mb-3">
                                            <h5 className="mb-3">Respondent Condition</h5>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range_start"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Age range:
                                                </label>
                                                <div className="col-md-10">
                                                    <Row className="d-flex align-items-center">
                                                        <Col className="col-md-6">
                                                            <InputGroup>
                                                                <div>
                                                                    <Input
                                                                        type="number"
                                                                        id="age_range_start"
                                                                        name="age_range_start"
                                                                        value={validation.values.age_range_start}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.age_range_start && validation.errors.age_range_start ? true : false
                                                                        }
                                                                        min={1}
                                                                        max={99}
                                                                    />
                                                                    {validation.touched.age_range_start && validation.errors.age_range_start ? (
                                                                        <FormFeedback type="invalid">{validation.errors.age_range_start}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                                <span className="input-group-text">-</span>
                                                                <div>
                                                                    <Input
                                                                        type="number"
                                                                        id="age_range_end"
                                                                        name="age_range_end"
                                                                        value={validation.values.age_range_end}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.age_range_end && validation.errors.age_range_end ? true : false
                                                                        }
                                                                        min={1}
                                                                        max={99}
                                                                    />
                                                                    {validation.touched.age_range_start && validation.errors.age_range_start ? (
                                                                        <FormFeedback type="invalid">{validation.errors.age_range_start}</FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Gender:
                                                </label>
                                                <div className="col-md-2">
                                                    <CreatableSelect
                                                        id="gender"
                                                        name="gender"
                                                        value={genderSelected}
                                                        onChange={(selectedOption) => {
                                                            setGenderSelected(selectedOption)
                                                            validation.setFieldValue("gender", selectedOption.value)
                                                            isEmpty(validation.values.gender) ? setGenderInvalid(true) : setGenderInvalid(false)
                                                            // validation.touched.gender && validation.errors.gender
                                                            //     ? setGenderInvalid(true)
                                                            //     : setGenderInvalid(false)
                                                        }}
                                                        // onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        options={genderDropdown}
                                                        // className="basic-single"
                                                        // classNamePrefix="select"
                                                        className={genderInvalidClass}
                                                        aria-invalid={
                                                            validation.touched.gender && validation.errors.gender ? true : false
                                                        }
                                                    />
                                                    {validation.touched.gender && validation.errors.gender ? (
                                                        <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Nationality:
                                                </label>
                                                <div className="col-md-2">
                                                    <CreatableSelect
                                                        id="nationality"
                                                        name="nationality"
                                                        value={nationalitySelected}
                                                        onChange={(selectedOption) => {
                                                            setNationalitySelected(selectedOption)
                                                            validation.setFieldValue("nationality", selectedOption.value)
                                                            validation.touched.gender && validation.errors.gender
                                                                ? setNationalityInvalid(true)
                                                                : setNationalityInvalid(false)
                                                        }}
                                                        onSubmit={() => {
                                                            validation.touched.gender && validation.errors.gender
                                                                ? setNationalityInvalid(true)
                                                                : setNationalityInvalid(false)
                                                        }}
                                                        options={nationalityDropdown}
                                                        className={nationalityInvalidClass}
                                                        // className="basic-single"
                                                        // classNamePrefix="select"
                                                        aria-invalid={
                                                            validation.touched.nationality && validation.errors.nationality ? true : false
                                                        }
                                                    />
                                                    {validation.touched.nationality && validation.errors.nationality ? (
                                                        <FormFeedback type="invalid">{validation.errors.nationality}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="age_range"
                                                    className="col-md-2 col-form-label"
                                                >
                                                    Martial Status:
                                                </label>
                                                <div className="col-md-2">
                                                    <CreatableSelect
                                                        id="martial_status"
                                                        name="martial_status"
                                                        value={martialStatusSelected}
                                                        onChange={(selectedOption) => {
                                                            setMartialStatusSelected(selectedOption)
                                                            validation.setFieldValue("martial_status", selectedOption.value)
                                                        }}
                                                        onSubmit={() => {
                                                            validation.touched.gender && validation.errors.gender
                                                                ? setMartialInvalid(true)
                                                                : setMartialInvalid(false)
                                                        }}
                                                        options={martialStatusDropdown}
                                                        className={martialInvalidClass}
                                                        // className="basic-single"
                                                        // classNamePrefix="select"
                                                        invalid={
                                                            validation.touched.martial_status && validation.errors.martial_status ? true : false
                                                        }
                                                    />
                                                    {validation.touched.martial_status && validation.errors.martial_status ? (
                                                        <FormFeedback type="invalid">{validation.errors.martial_status}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Row>
                                        </Row> */}

                                        <Row className="mb-3">
                                            <h5 className="mb-3" style={{ display: 'flex', alignItems: 'center', gap: '4px'}}>
                                                <span >AI add-on Question Criteria</span>
                                                <i className="bx bxs-info-circle hoverShow" id="tooltip-ai-add-on"></i>
                                            </h5>
                                            <Tooltip 
                                                placement="top" 
                                                isOpen={tooltipOpen} 
                                                target="tooltip-ai-add-on" 
                                                toggle={toggle}
                                                >
                                                <div >Depending on the respondent’s answers, AI will generate add-on questions by 5 criteria as below (Relevance, Clarity, Engagement Potential, Response usefulness, Information gain as default).​ Please input an example for each criterion.</div>
                                            </Tooltip>
                                            <Col md="3">
                                                <Nav pills className="flex-column">
                                                    <FieldArray name="criterias">
                                                        {({ push, remove }) => (
                                                            <>
                                                                {validation.values.criterias.map((cr, index) => (
                                                                    <NavItem key={index}>
                                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === `${index + 1}`, })} onClick={() => { toggleVertical(`${index + 1}`); }}>
                                                                            
                                                                            {
                                                                                verticalActiveTab === `${index + 1}`
                                                                                ? (
                                                                                    <>
                                                                                        <Input
                                                                                            id={`criterias.${index}.criteria_name`}
                                                                                            name={`criterias.${index}.criteria_name`}
                                                                                            className="form-control"
                                                                                            type="text"
                                                                                            placeholder="Enter Criteria Name"
                                                                                            onChange={(e) => customHandleChange(e, index)}
                                                                                            onBlur={validation.handleBlur}
                                                                                            value={cr.criteria_name}
                                                                                            invalid={!!(validation.touched.criterias?.[index]?.criteria_name && validation.errors.criterias?.[index]?.criteria_name)}
                                                                                        />
                                                                                        {
                                                                                            !!(validation.touched.criterias?.[index]?.criteria_name && validation.errors.criterias?.[index]?.criteria_name)? (
                                                                                                <FormFeedback type="invalid">
                                                                                                {validation.errors.criterias[index].criteria_name}
                                                                                                </FormFeedback>
                                                                                            ) : null
                                                                                        }
                                                                                    </>
                                                                                )
                                                                                : <>{cr.criteria_name}</>
                                                                            }
                                                                            
                                                                        </NavLink>
                                                                    </NavItem>
                                                                ))}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Nav>
                                            </Col>
                                            <Col md="9">
                                            
                                                <TabContent
                                                    activeTab={verticalActiveTab}
                                                    className="text-muted mt-4 mt-md-0"
                                                >
                                                    {/* <TabPane tabId="1"> */}
                                                    <FieldArray name="criterias">
                                                        {({ push, remove }) => (
                                                            <>
                                                                {
                                                                    validation.values.criterias
                                                                    .map((criterias, index) => (
                                                                        <TabPane tabId={`${index + 1}`} key={index}>
                                                                            <Row className="mb-3">
                                                                                <InputGroup>
                                                                                    <div className="input-group-text">
                                                                                        <div className="form-check-primary">
                                                                                            <Input
                                                                                                className="form-check-input pl-5"
                                                                                                id={`criterias.${index}.criteria_checkbox`}
                                                                                                name={`criterias.${index}.criteria_checkbox`}
                                                                                                type="checkbox"
                                                                                                checked={criterias?.criteria_checkbox}
                                                                                                onClick={(e)=> {
                                                                                                    const value = validation.values.criterias[index].criteria_checkbox
                                                                                                    const name = `criterias.${index}.criteria_checkbox`
                                                                                                    validation.setFieldValue(name, !value);
                                                                                                }}
                                                                                                readOnly
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <Input
                                                                                        id={`criterias.${index}.criteria_text`}
                                                                                        name={`criterias.${index}.criteria_text`}
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Enter Criteria Name"
                                                                                        onChange={(e) => customHandleChange(e, index)}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={criterias.criteria_text}
                                                                                        invalid={!!(validation.touched.criterias?.[index]?.criteria_text && validation.errors.criterias?.[index]?.criteria_text)}
                                                                                    />
                                                                                    {
                                                                                        !!(validation.touched.criterias?.[index]?.criteria_text && validation.errors.criterias?.[index]?.criteria_text) && (
                                                                                            <FormFeedback type="invalid">{validation.errors.criterias[index].criteria_text}</FormFeedback>
                                                                                        ) 
                                                                                    }
                                                                                </InputGroup>
                                                                            </Row>
                                                                            <Row className="mb-3">
                                                                                <Label
                                                                                    htmlFor="criteria_clarity_context"
                                                                                    className="col-md-3 col-form-label max-col-x"
                                                                                >
                                                                                    Previous Answers:
                                                                                </Label>
                                                                                <div className="col">
                                                                                    <Input
                                                                                        id={`criterias.${index}.criteria_context`}
                                                                                        name={`criterias.${index}.criteria_context`}
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        placeholder="Enter Criteria Name"
                                                                                        onChange={(e) => customHandleChange(e, index)}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={criterias.criteria_context}
                                                                                        invalid={!!(validation.touched.criterias?.[index]?.criteria_context && validation.errors.criterias?.[index]?.criteria_context)}
                                                                                    />
                                                                                    {
                                                                                        !!(validation.touched.criterias?.[index]?.criteria_context && validation.errors.criterias?.[index]?.criteria_context) && (
                                                                                            <FormFeedback type="invalid">{validation.errors.criterias[index].criteria_context}</FormFeedback>
                                                                                        ) 
                                                                                    }
                                                                                </div>
                                                                            </Row>
                                                                            <Row className="mb-3">
                                                                                <Label
                                                                                    htmlFor="criteria_clarity_context"
                                                                                    className="col-md-3 col-form-label max-col-x"
                                                                                >
                                                                                    Good (Score 2):
                                                                                </Label>
                                                                                <div className="col">
                                                                                    <Input
                                                                                        id={`criterias.${index}.criteria_good`}
                                                                                        name={`criterias.${index}.criteria_good`}
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        placeholder="Enter Criteria Name"
                                                                                        onChange={(e) => customHandleChange(e, index)}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={criterias.criteria_good}
                                                                                        invalid={!!(validation.touched.criterias?.[index]?.criteria_good && validation.errors.criterias?.[index]?.criteria_good)}
                                                                                    />
                                                                                    {
                                                                                        !!(validation.touched.criterias?.[index]?.criteria_good && validation.errors.criterias?.[index]?.criteria_good) && (
                                                                                            <FormFeedback type="invalid">{validation.errors.criterias[index].criteria_good}</FormFeedback>
                                                                                        ) 
                                                                                    }
                                                                                </div>
                                                                            </Row>
                                                                            <Row className="mb-3">
                                                                                <Label
                                                                                    htmlFor="criteria_clarity_context"
                                                                                    className="col-md-3 col-form-label max-col-x"
                                                                                >
                                                                                    Poor (Score 1):
                                                                                </Label>
                                                                                <div className="col">
                                                                                    <Input
                                                                                        id={`criterias.${index}.criteria_poor`}
                                                                                        name={`criterias.${index}.criteria_poor`}
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        placeholder="Enter Criteria Name"
                                                                                        onChange={(e) => customHandleChange(e, index)}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={criterias.criteria_poor}
                                                                                        invalid={!!(validation.touched.criterias?.[index]?.criteria_poor && validation.errors.criterias?.[index]?.criteria_poor)}
                                                                                    />
                                                                                    {
                                                                                        !!(validation.touched.criterias?.[index]?.criteria_poor && validation.errors.criterias?.[index]?.criteria_poor) && (
                                                                                            <FormFeedback type="invalid">{validation.errors.criterias[index].criteria_poor}</FormFeedback>
                                                                                        ) 
                                                                                    }
                                                                                </div>
                                                                            </Row>
                                                                            <Row className="mb-3">
                                                                                <Label
                                                                                    htmlFor="criteria_clarity_context"
                                                                                    className="col-md-3 col-form-label max-col-x"
                                                                                >
                                                                                    Bad (Score 0):
                                                                                </Label>
                                                                                <div className="col">
                                                                                    <Input
                                                                                        id={`criterias.${index}.criteria_bad`}
                                                                                        name={`criterias.${index}.criteria_bad`}
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        placeholder="Enter Criteria Name"
                                                                                        onChange={(e) => customHandleChange(e, index)}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={criterias.criteria_bad}
                                                                                        invalid={!!(validation.touched.criterias?.[index]?.criteria_bad && validation.errors.criterias?.[index]?.criteria_bad)}
                                                                                    />
                                                                                    {
                                                                                        !!(validation.touched.criterias?.[index]?.criteria_bad && validation.errors.criterias?.[index]?.criteria_bad) && (
                                                                                            <FormFeedback type="invalid">{validation.errors.criterias[index].criteria_bad}</FormFeedback>
                                                                                        ) 
                                                                                    }
                                                                                </div>
                                                                            </Row>
                                                                        </TabPane>
                                                                    ))
                                                                }
                                                                {/* <Button
                                                                    color="danger"
                                                                    type="button"
                                                                    onClick={() => remove(index)} // Correctly removes the banner at this index
                                                                >
                                                                    Remove
                                                                </Button>
                                                                <br/>
                                                                <Button color="primary" type="button" 
                                                                    onClick={() =>
                                                                        push({
                                                                            criteria_name: "", // Add a new empty banner
                                                                            criteria_text: "",
                                                                            criteria_context: "",
                                                                            criteria_good: "",
                                                                            criteria_poor: "",
                                                                            criteria_bad: "",
                                                                        })
                                                                    }
                                                                >
                                                                    Add Criteria
                                                                </Button> */}
                                                            </>)
                                                        }
                                                    </FieldArray>
                                                    
                                                        
                                                    {/* </TabPane> */}
                                                </TabContent>
                                            </Col>
                                        </Row>

                                        {/* <Row className="mb-3">
                                            <div className="bTopic">
                                                <h6>Remarks</h6>
                                            </div>
                                            <div>
                                                <Input
                                                    type="textarea"
                                                    rows="5"
                                                    id="remarks"
                                                    name="remarks"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.remarks}
                                                    invalid={
                                                        validation.touched.remarks && validation.errors.remarks ? true : false
                                                    }
                                                />
                                                {validation.touched.remarks && validation.errors.remarks ? (
                                                    <FormFeedback type="invalid">{validation.errors.remarks}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row> */}
                                        <Row className="mb-3">
                                            <Label className="h5" htmlFor="rules">
                                                <span >User’s Answer Evaluation Criteria</span>
                                            </Label>
                                            <div className="bTopic">
                                                <h6>Rules</h6>
                                                <i className="bx bxs-info-circle hoverShow" id="tooltip-rules"></i>
                                            </div>
                                            <Tooltip 
                                                placement="top" 
                                                isOpen={tooltipOpen2} 
                                                target="tooltip-rules" 
                                                toggle={toggle2}
                                                >
                                                <div>To prevent respondents from giving random answers, we established answer evaluation criteria. If respondents fail to meet these criteria multiple times, they will not be able to pass the survey. Researchers can edit the 'Answer Evaluation Criteria' according to their needs.​
*The number in '()' indicates the limit of unqualified answers before the respondent receives a reminder.​
*The number in '{}' indicates the limit of unqualified answers before the survey is terminated for the respondent.​
*The number in '[]' represents the total number of 'AI-generated add-on' questions.</div>
                                            </Tooltip>
                                            <div>
                                                <Input
                                                    type="textarea"
                                                    rows="5"
                                                    id="rules"
                                                    name="rules"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.rules}
                                                    invalid={
                                                        validation.touched.rules && validation.errors.rules ? true : false
                                                    }
                                                />
                                                {validation.touched.rules && validation.errors.rules ? (
                                                    <FormFeedback type="invalid">{validation.errors.rules}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <div className="bTopic">
                                                <h6>Reminder Message:</h6>
                                                <span>Message to warning people who have given unqualified answers multiple times.</span>
                                            </div>
                                            <div>
                                                <Input
                                                    type="textarea"
                                                    rows="5"
                                                    id="reminder_message"
                                                    name="reminder_message"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.reminder_message}
                                                    invalid={
                                                        validation.touched.reminder_message && validation.errors.reminder_message ? true : false
                                                    }
                                                />
                                                {validation.touched.reminder_message && validation.errors.reminder_message ? (
                                                    <FormFeedback type="invalid">{validation.errors.reminder_message}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <div className="bTopic">
                                                <h6>Fail Message:</h6>
                                                <span>Message to people who have failed the scoring tests.</span>
                                            </div>
                                            <div>
                                                <Input
                                                    type="textarea"
                                                    rows="5"
                                                    id="fail_message"
                                                    name="fail_message"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.fail_message}
                                                    invalid={
                                                        validation.touched.fail_message && validation.errors.fail_message ? true : false
                                                    }
                                                />
                                                {validation.touched.fail_message && validation.errors.fail_message ? (
                                                    <FormFeedback type="invalid">{validation.errors.fail_message}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <div className="bTopic">
                                                <h6>Pass Message:</h6>
                                                <span>Message to people who completed the survey</span>
                                            </div>
                                            <div>
                                                <Input
                                                    type="textarea"
                                                    rows="5"
                                                    id="pass_message"
                                                    name="pass_message"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.pass_message}
                                                    invalid={
                                                        validation.touched.pass_message && validation.errors.pass_message ? true : false
                                                    }
                                                />
                                                {validation.touched.pass_message && validation.errors.pass_message ? (
                                                    <FormFeedback type="invalid">{validation.errors.pass_message}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <div style={{display: "flex", justifyContent: "center"}}>
                                                    {/* <Link to={"/questionaire/create"} color="primary" className="btn btn-primary waves-effect waves-light">Draft Questionaire</Link> */}
                                                    <Button type="submit" color="primary" className="btn btn-primary waves-effect waves-light">
                                                        Draft Questionnaire {sendQuestion && <Spinner size="sm" className="ms-2" color="light" />}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Form>
                        </FormikProvider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };


export default withTranslation()(QuestionaireCategoryTemplate)
